import { IFilter } from "src/@redux/listRedux";
import commonDataAccess from "../commonDataAccess";

class tabDataAccess {
  validateTabName(value: any, tabId: any) {
    return commonDataAccess.getData("tab/validateTabName", {
      id: tabId,
      value: value,
    });
  }
  getTabDropdown = () => {
    return commonDataAccess.getData("tab/get/dropdownList");
  };
  getTabDropdownListByProcedure = (procedureId: number) => {
    return commonDataAccess.getData(
      "procedure/get/tabdropdownListbyprocedure",
      {
        procedureId: procedureId,
      }
    );
  };
  getList = (params: {
    pageNo;
    pageSize;
    sortData;
    filters: Array<IFilter>;
  }) => {
    let _filters = {};
    params.filters.forEach((item) => {
      if (item.value && item.value.length > 0) {
        _filters["filter." + item.propertyName] = item.value + "-" + item.type;
      }
    });
    let parameters = {
      pageNo: params.pageNo,
      pageSize: params.pageSize,
      sortPreference: params.sortData,
      ..._filters,
    };

    return commonDataAccess.getData("tab/getall", parameters);
  };

  deleteTab = (tabIds: string) => {
    return commonDataAccess.deleteData("tab/delete", {
      ids: tabIds,
    });
  };
  getTabInfo = (id: any) => {
    return commonDataAccess.getData("tab/get/info", {
      id: id,
    });
  };
  saveTab = (data: any) => {
    return commonDataAccess.postData("tab/save/info", data);
  };
}

export default new tabDataAccess();
