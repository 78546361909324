import { createReducer } from "@reduxjs/toolkit";
import { ProcedureStepConfigModel } from "src/pages/Procedure/Edit/StepEditModal/shared-components/step-edit.config";
import {
  deleteProcedureStepSuccess,
  getAllForDropdownByTypeProcedureSuccess,
  getAuditInfoSuccess,
  getAuditsSuccess,
  getDocHistorySuccess,
  getInProgressListSuccess,
  getProcedureListSuccess,
  getProcedureStepListSuccess,
  getSelectedProcedureBasicInfoSuccess,
  getTabDropdownListByProcedureSuccess,
  getTabDropdownSuccess,
  getTrainingCourseEmployeesSuccess,
  loadingList,
  setCaNotApplicable,
  setProcedureStepConfig,
  setSubmitButtonType,
  updateProcedureIdSuccess,
  updateProcedureStepSuccess,
  updateStepSequencePosition,
  verifyScoreProcedure,
  verifyTabProcedure,
} from "./procedureActions";
export interface IFilter {
  id: string;
  propertyName: string;
  value: Array<string> | string | any;
  type: Array<string> | string;
  placeholder: string;
  hidden?: boolean;
}
export interface IProcedureState {
  inProgressList?: Array<any>;
  procedureDropdownData: Array<any>;
  tabDropdown?: any[];
  tabDropdownByProcedure?: any[];
  procedures: Array<any>;
  dataCount: number;
  procedureStepList: any[];
  procedureStepConfig?: Partial<ProcedureStepConfigModel>;
  selectedProcedureBasicInfo?: any;
  isScoreProcedure?: boolean;
  isTabProcedure?: boolean;
  isLoading: boolean;
  procedureId: number;
  employees: any[];
  stepSequencePosition: number;
  submitButtonType: string;
  documents: any[];
  audit: any;
  audits: any[];
}

const initialState: IProcedureState = {
  inProgressList: [],
  procedureDropdownData: [],
  procedures: [],
  dataCount: 0,
  procedureStepList: [],
  isLoading: false,
  procedureId: 0,
  employees: [],
  stepSequencePosition: 0,
  submitButtonType: "",
  documents: [],
  audit: {},
  audits: [],
};

export const procedureReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getProcedureListSuccess, (state, action) => {
      state = {
        ...state,
        procedures: action.payload?.data,
        dataCount: action.payload?.dataCount,
      };
      return state;
    })
    .addCase(getAllForDropdownByTypeProcedureSuccess, (state, action) => {
      state = {
        ...state,
        procedureDropdownData: action.payload?.data,
      };
      return state;
    })
    .addCase(loadingList, (state, action) => {
      state = {
        ...state,
        isLoading: action.payload,
      };
      return state;
    })
    .addCase(getTabDropdownSuccess, (state, action) => {
      state = {
        ...state,
        tabDropdown: action.payload,
      };
      return state;
    })
    .addCase(getTabDropdownListByProcedureSuccess, (state, action) => {
      state = {
        ...state,
        tabDropdownByProcedure: action.payload,
      };
      return state;
    })
    .addCase(getProcedureStepListSuccess, (state, action) => {
      console.log("getProcedureStepListSuccess");
      state = {
        ...state,
        procedureStepList: action.payload,
      };
      return state;
    })
    .addCase(updateProcedureStepSuccess, (state, action) => {
      let stepList = state.procedureStepList.slice();

      if (action.payload.stepId === 0) {
        stepList.push(action.payload.stepData);
      } else {
        stepList = stepList.map((item) => {
          if (item.id === action.payload.stepId) {
            return action.payload.stepData;
          }
          return item;
        });
      }
      console.log("updateProcedureStepListSuccess");
      state = {
        ...state,
        procedureStepList: stepList,
      };
      return state;
    })
    .addCase(setProcedureStepConfig, (state, action) => {
      state = {
        ...state,
        procedureStepConfig: action.payload,
      };
      return state;
    })
    .addCase(updateStepSequencePosition, (state, action) => {
      state = {
        ...state,
        stepSequencePosition: action.payload,
      };
      return state;
    })
    .addCase(getSelectedProcedureBasicInfoSuccess, (state, action) => {
      state = {
        ...state,
        selectedProcedureBasicInfo: action.payload,
      };
      return state;
    })
    .addCase(setCaNotApplicable, (state, action) => {
      let cpy = Object.assign({}, state.procedureStepConfig);
      cpy.isCANotApplicable = action.payload;
      state = {
        ...state,
        procedureStepConfig: cpy,
      };
      return state;
    })
    .addCase(verifyScoreProcedure, (state, action) => {
      console.log(action.payload);
      state = {
        ...state,
        isScoreProcedure: action.payload,
      };
      return state;
    })
    .addCase(verifyTabProcedure, (state, action) => {
      state = {
        ...state,
        isTabProcedure: action.payload,
      };
      return state;
    })
    .addCase(deleteProcedureStepSuccess, (state, action) => {
      state = {
        ...state,
        procedureStepList: action.payload,
      };
      return state;
    })
    .addCase(updateProcedureIdSuccess, (state, action) => {
      state = {
        ...state,
        procedureId: action.payload.Id,
      };
      return state;
    })
    .addCase(getTrainingCourseEmployeesSuccess, (state, action) => {
      state = {
        ...state,
        employees: action.payload,
      };
      return state;
    })
    .addCase(setSubmitButtonType, (state, action) => {
      state = {
        ...state,
        submitButtonType: action.payload,
      };
      return state;
    })
    .addCase(getDocHistorySuccess, (state, action) => {
      state = {
        ...state,
        documents: action.payload?.data,
        dataCount: action.payload?.dataCount,
      };
      return state;
    })
    .addCase(getAuditInfoSuccess, (state, action) => {
      state = {
        ...state,
        audit: action.payload,
      };
      return state;
    })
    .addCase(getAuditsSuccess, (state, action) => {
      state = {
        ...state,
        audits: action.payload,
      };
      return state;
    })
    .addCase(getInProgressListSuccess, (state, action) => {
      state = {
        ...state,
        inProgressList: action.payload,
      };
      return state;
    });
});
