import { createReducer } from "@reduxjs/toolkit";
import {
  AddSODetails,
  getSalesOrderListSuccess,
  loadingList,
  RemoveSODetails,
  saveSalesOrderListSuccess,
  SetSODetails,
  UpdateSODetails,
} from "./salesOrderAction";

export interface ISalesOrderState {
  productDetails: any[];
  salesOrderList: Array<any>;
  dataCount: number;
  isLoading: boolean;
}

const initialState: ISalesOrderState = {
  salesOrderList: [],
  productDetails: [],
  dataCount: 0,
  isLoading: false,
};

export const salesOrderReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getSalesOrderListSuccess, (state, action) => {
      state = {
        ...state,
        salesOrderList: action.payload?.data,
        dataCount: action.payload?.dataCount,
      };
      return state;
    })

    .addCase(saveSalesOrderListSuccess, (state, action) => {
      state = {
        ...state,
        salesOrderList: action.payload,
      };
      return state;
    })
    .addCase(SetSODetails, (state, action) => {
      console.log(action.payload);
      let data = (action.payload ?? []).map((item, index) => {
        let cpy = { ...item };
        cpy["key"] = index + 1;
        return cpy;
      });
      console.log(data);
      state = {
        ...state,
        productDetails: data,
      };
      return state;
    })
    .addCase(UpdateSODetails, (state, action) => {
      //console.log(action.payload);
      console.log(state.productDetails);
      let data = (state.productDetails ?? []).map((item, index) => {
        let cpy = { ...item };
        console.log(index);
        if (item.id == action.payload.id && action.payload.key == index + 1) {
          cpy = action.payload;
        }
        //cpy["key"] = index + 1;
        return cpy;
      });
      console.log(data);
      state = {
        ...state,
        productDetails: data,
      };
      return state;
    })
    .addCase(AddSODetails, (state, action) => {
      let update = {
        ...action.payload,
        key: (state.productDetails ?? [])?.length + 1,
      };
      state = {
        ...state,
        productDetails: (state?.productDetails ?? []).concat(update),
      };
      return state;
    })
    .addCase(RemoveSODetails, (state, action) => {
      let filter = state.productDetails.filter(
        (item) => item.key !== action.payload.key
      );
      state = {
        ...state,
        productDetails: filter,
      };
      return state;
    })

    .addCase(loadingList, (state, action) => {
      state = {
        ...state,
        isLoading: action.payload,
      };
      return state;
    });
});
