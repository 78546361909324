import { createAction } from "@reduxjs/toolkit";

export const savingData = createAction<boolean>("[Common] Saving data");
export const loadingData = createAction<boolean>("[Common] Loading data");
export const setEditPageTitle = createAction<string>(
  "[Common] Set edit Page title"
);
export const setSidebarVisible = createAction<boolean>(
  "[Common] Set sidebar visibility"
);
