import { createReducer } from "@reduxjs/toolkit";
import {
  loadingData,
  savingData,
  setEditPageTitle,
  setSidebarVisible,
} from "./commonAction";

export interface Common {
  isSaving: boolean;
  isLoading: boolean;
  editPageTitle: string;
  sidebarVisible: boolean;
}
const initialState: Partial<Common> = {
  editPageTitle: "",
  sidebarVisible: true,
};

export const commonReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(savingData, (state, action) => {
      state = {
        ...state,
        isSaving: action.payload,
      };
      return state;
    })
    .addCase(loadingData, (state, action) => {
      state = {
        ...state,
        isLoading: action.payload,
      };
      return state;
    })
    .addCase(setEditPageTitle, (state, action) => {
      state = {
        ...state,
        editPageTitle: action.payload,
      };
      return state;
    })
    .addCase(setSidebarVisible, (state, action) => {
      state = {
        ...state,
        sidebarVisible: action.payload,
      };
      return state;
    });
});
