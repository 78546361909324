import { IFilter } from "src/@redux/listRedux";
import commonDataAccess from "../commonDataAccess";

class ProcedureDataAccess {
  savePermission(payload: any[], procedureId: number, permissionType: string) {
    return commonDataAccess.postData("procedure/save/Permission", payload, {
      procedureId: procedureId,
      permissionType: permissionType,
    });
  }
  getAllByTypeProcedure = (typeProcedure) => {
    return commonDataAccess.getData("procedure/get/allByTypeProcedure", {
      typeProcedure: typeProcedure,
    });
  };
  getAllForDropdownByTypeProcedure = (
    typeProcedure,
    limit = 0,
    searchValue = "",
    selectedIdsString: any[] = []
  ) => {
    let ids = selectedIdsString.toString();
    //console.log(ids);
    return commonDataAccess.getData(
      "procedure/get/DropdownListByTypeProcedure",
      {
        typeProcedure: typeProcedure,
        limit: limit,
        searchValue: searchValue,
        selectedIdsString: ids,
      }
    );
  };
  getAllDailyChecksForDropdownByTypeProcedure = (
    typeProcedure,
    limit = 0,
    searchValue = "",
    selectedIdsString: any[] = []
  ) => {
    let ids = selectedIdsString.toString();
    //console.log(ids);
    return commonDataAccess.getData(
      "procedure/get/DailyCheckDropdownListByTypeProcedure",
      {
        typeProcedure: typeProcedure,
        limit: limit,
        searchValue: searchValue,
        selectedIdsString: ids,
      }
    );
  };
  getProcedureList = (params: {
    pageNo;
    pageSize;
    sortData;
    filters: Array<IFilter>;
  }) => {
    let _filters = {};
    params.filters.forEach((item) => {
      if (item.value && item.value.length > 0) {
        _filters["filter." + item.propertyName] = item.value + "-" + item.type;
      }
    });
    let parameters = {
      pageNo: params.pageNo,
      pageSize: params.pageSize,
      sortPreference: params.sortData,
      ..._filters,
    };
    return commonDataAccess.getData("procedure/getall", parameters);
  };
  getSequentialTabDropdown = (procedureId: number) => {
    return commonDataAccess.getData(
      "procedure/get/tabdropdownListbyprocedure",
      {
        procedureId: procedureId,
      }
    );
  };
  getProcedureBasicInfo = (procedureId: number) => {
    return commonDataAccess.getData("procedure/get/basicinfo", {
      id: procedureId,
    });
  };
  saveProcedureBasicInfo = (payload: any) => {
    return commonDataAccess.postData("procedure/save/basicinfo", payload);
  };
  getProcedureScore = (procedureId: number) => {
    return commonDataAccess.getData("procedure/get/score", {
      id: procedureId,
    });
  };
  saveScore = (payload: any) => {
    return commonDataAccess.postData("procedure/save/score", payload);
  };
  getProcedureAttachments = (procedureId: number) => {
    return commonDataAccess.getData("procedure/get/attachments", {
      id: procedureId,
    });
  };
  saveAttachments = (payload: FormData, docHistoryName: string) => {
    return commonDataAccess.postFormData(
      "procedure/save/attachments",
      payload,
      {
        docHistoryName: docHistoryName,
      }
    );
  };
  getProcedureNotes = (procedureId: number) => {
    return commonDataAccess.getData("procedure/get/notes", {
      id: procedureId,
    });
  };
  saveNotes = (payload: any) => {
    return commonDataAccess.postData("procedure/save/notes", payload);
  };
  saveProcedureSequence = (
    procedureId,
    typeProcedure,
    newPosition,
    IsDeleteProcedure = false
  ) => {
    return commonDataAccess.getData("procedure/update/SequencePosition", {
      procedureId: procedureId,
      newPosition: newPosition,
      typeProcedure: typeProcedure,
      IsDeleteProcedure: IsDeleteProcedure,
    });
  };
  getAllProcedureDropdown = () => {
    return commonDataAccess.getData("procedure/get/allProcedureDropdown");
  };
  getTrainingCourseEmployees = (procedureId: any) => {
    return commonDataAccess.getData("procedure/get/trainingemployees", {
      id: procedureId,
    });
  };
  getPermittedUsers = (procedureId: any, permissionType: any) => {
    return commonDataAccess.getData("procedure/get/permittedUserDepts", {
      id: procedureId,
      permissionType: permissionType,
    });
  };
  getDocumentHistory = (params: {
    pageNo;
    pageSize;
    sortData;
    filters: Array<IFilter>;
  }) => {
    let _filters = {};
    params.filters.forEach((item) => {
      if (item.value && item.value.length > 0) {
        _filters["filter." + item.propertyName] = item.value + "-" + item.type;
      }
    });
    let parameters = {
      pageNo: params.pageNo,
      pageSize: params.pageSize,
      sortPreference: params.sortData,
      ..._filters,
    };
    return commonDataAccess.getData(
      "procedure/get/active-documents",
      parameters
    );
  };
  copyProcedure = (procedureId) => {
    return commonDataAccess.postData("procedure/copy", null, {
      procedureId,
    });
  };
  getAuditInfo = (procedureId: number) => {
    return commonDataAccess.getData("procedure/get/auditInfo", {
      id: procedureId,
    });
  };
  saveAuditInfo = (payload: any) => {
    return commonDataAccess.postData("procedure/save/auditinfo", payload);
  };
  getAudits = () => {
    return commonDataAccess.getData("procedure/get/auditInfo");
  };
  getList = (params: {
    pageNo;
    pageSize;
    sortData;
    filters: Array<IFilter>;
  }) => {
    let _filters = {};
    params.filters.forEach((item) => {
      if (item.value && item.value.length > 0) {
        _filters["filter." + item.propertyName] = item.value + "-" + item.type;
      }
    });
    let parameters = {
      pageNo: params.pageNo,
      pageSize: params.pageSize,
      sortPreference: params.sortData,
      ..._filters,
    };

    return commonDataAccess.getData(
      "ProcedureJob/getallInProgress",
      parameters
    );
  };
  deleteProcedure = (ProcedureIds: string) => {
    return commonDataAccess.deleteData("Procedure/deleteAllProcedure", {
      ids: ProcedureIds,
    });
  };
  printCheckReport = (
    organizationId: any,
    data: any,
    downloadProgress: (progress: any) => void
  ) => {
    return commonDataAccess.downloadPost({
      url: `ProceduresReport/get/ProcedureReport/${organizationId}`,
      payload: data,
      onDowloadProgress: downloadProgress,
      type: "Legacy",
    });
  };
  getRecipeProducts = (procedureId: number) => {
    return commonDataAccess.getData("procedure/get/RecipeProducts", {
      procedureId: procedureId,
    });
  };
  getRecipeInfo = async (id) => {
    var result = await commonDataAccess.getData(`procedure/get/recipeinfo`, {
      id: id,
    });
    console.log(result);
    return result;
  };
  getIngredientsInfo = async (id) => {
    var result = await commonDataAccess.getData(
      `procedure/get/ingredientInfo`,
      {
        id: id,
      }
    );
    console.log(result);
    return result;
  };
  getNutritionalInfo = async (id) => {
    var result = await commonDataAccess.getData(
      `procedure/get/nutritionalInfo`,
      {
        id: id,
      }
    );
    console.log(result);
    return result;
  };
  getMicrobioInfo = async (id) => {
    var result = await commonDataAccess.getData(`procedure/get/microbioInfo`, {
      id: id,
    });
    console.log(result);
    return result;
  };
  saveRecipeInfo = (payload: any) => {
    return commonDataAccess.postData("procedure/save/recipeinfo", payload);
  };
  saveRecipeIngredientsInfo = (payload: any, procedureId: any) => {
    return commonDataAccess.postData(
      "procedure/save/recipeIngredients",
      payload,
      {
        procedureId: procedureId,
      }
    );
  };
  saveNutritionalInformation = (payload: any, procedureId: any) => {
    return commonDataAccess.postData(
      "procedure/save/nutritionalInformation",
      payload,
      {
        procedureId: procedureId,
      }
    );
  };
  saveFoodSafetyControl = (payload: any, procedureId: any) => {
    return commonDataAccess.postData(
      "procedure/save/foodSafetyControl",
      payload,
      {
        procedureId: procedureId,
      }
    );
  };
  getRecipedropdown = () => {
    return commonDataAccess.getData("procedure/get/recipedropdown");
  };
}
export default new ProcedureDataAccess();
